import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faExternalLinkAlt, faTimesCircle, faCheckCircle, faCalendarAlt, faCodeBranch, faShoppingCart, faFolder, faMapMarkedAlt, faPager, faFileCode, faDownload } from "@fortawesome/free-solid-svg-icons";
import { faBootstrap, faGithub, faJs, faReact, faSass } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Card, Image, Button, Container, ListGroup, Tooltip, OverlayTrigger, Form, Navbar, Nav, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Ratio from 'react-bootstrap/Ratio';
import { Icon } from '@iconify/react';
import Presentation from "../assets/img/Onepager_full.png";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import RespImg from "../assets/img/resp_loistaa.png";
import DeployImg from "../assets/img/onepager07.png";
import ReactImg from "../assets/img/onepager06.png";
import Video1 from "../assets/img/onepager01.mp4";



export default () => {


  return (
    <>
      <Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand as={HashLink} to="#home" className="me-lg-3 d-flex align-items-center">
            <Image src={ReactHero} />
            <span className="ms-2 brand-text d-none d-md-inline">loistaa.fi One Pager</span>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
          <span className="ms-2 brand-text d-none d-md-inline">Created with React.js</span>
          </div>
        </Container>
      </Navbar>
      <section className="section-header overflow-hidden pt-5 pt-lg-6 pb-9 pb-lg-12 bg-primary text-white" id="home">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <div className="react-big-icon d-none d-lg-block"><span className="fab fa-react"></span></div>
              <p className="text-muted fw-light mb-5 h5">Single-page web application built using React.js, emphasizing component-based architecture for smooth scalability. It prioritizes responsiveness, security, and visual appeal, offering features like email form submission with reCAPTCHA and continuous deployment to Azure through GitHub Actions. This project reflects a pragmatic approach to web development, aiming for functionality and reliability.</p>
              <div className="d-flex align-items-center justify-content-center">
                <Button variant="secondary" as={Link} to="https://loistaa.fi" target="_blank" className="text-dark me-3">
                  Check out the live result <FontAwesomeIcon icon={faExternalLinkAlt} className="d-none d-sm-inline ms-1" />
                </Button></div>
              <div className="d-flex justify-content-center flex-column mb-6 mb-lg-5 mt-5">
                <div className="text-center">
                  
                </div>
              </div>
              <div className="d-flex justify-content-center flex-column mb-6 mb-lg-5 mt-5">
                <div className="text-center">
                  
                </div>
              </div>
            </Col>
          </Row>
          <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2">
            <svg className="fill-soft" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
              <path d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z" />
            </svg>
          </figure>
        </Container>
      </section>
      <div className="section pt-0">
        <Container className="mt-n10 mt-lg-n12 z-2">
          <Row className="justify-content-center">
            <Col xs={12}>
              <Image src={Presentation} alt="Onepager presentation" />
            </Col>
          </Row>
          <Row className="justify-content-center mt-5 mt-lg-6">
           <Col xs={6} md={3} className="text-center mb-4">
              <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
                <FontAwesomeIcon icon={faReact} className="text-secondary" />
              </div>
              <h3 className="fw-bolder">React.js</h3>
              <p className="text-gray">Efficient Frontend Development</p>
            </Col> 
            <Col xs={6} md={3} className="text-center mb-4">
              <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
                <FontAwesomeIcon icon={faBootstrap} className="text-secondary" />
              </div>
              <h3 className="fw-bolder">Bootstrap 5</h3>
              <p className="text-gray">Responsive components</p>
            </Col>
            
            <Col xs={6} md={3} className="text-center">
              <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
                <FontAwesomeIcon icon={faSass} className="text-secondary" />
              </div>
              <h3 className="fw-bolder">CSS and Sass</h3>
              <p className="text-gray">Custom styling</p>
            </Col>
            <Col xs={6} md={3} className="text-center">
              <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
              <Icon icon="logos:microsoft-azure" className="text-secondary"/>
              </div>
              <h3 className="fw-bolder">Microsoft Azure</h3>
              <p className="text-gray">Continuous deployment</p>
            </Col>
          </Row>
        </Container>
      </div>
      <section className="section section-md bg-soft pt-lg-3" id="features">
        <Container>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5} className="order-lg-2 mb-5 mb-lg-0">
              <h2>React.js</h2>
              <p className="mb-4">In developing this project, I used React.js and its component-based architecture. By breaking down the UI into reusable and modular components, I ensured easier maintenance, scalability, and code readability. Each component encapsulated its logic and styling, adhering to the Single Responsibility Principle (SRP). This approach facilitated better code organization, debugging, and testing, as each component focused on a single aspect of the UI or functionality. Moreover, I prioritized optimizing component lifecycles to ensure a smooth user experience, minimizing unnecessary renders and enhancing performance.</p>
              </Col>
            <Col lg={6} className="order-lg-1">
              <Image src={ReactImg} alt="App Preview" />
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5}>
              <h2>Responsiveness</h2>
              <p className="mb-4">My one-pager project prioritizes delivering a seamless user experience. Intuitive navigation between 'screens' is facilitated by smooth scrolling or arrow clicks, enhancing ease of exploration.  I utilized the window size hook combined with conditional rendering based on Bootstrap grid to ensure compatibility across various screen sizes. Extensive testing was conducted to address challenges, particularly regarding viewport height discrepancies on different devices. Through meticulous adjustments and consideration of address bars that might overlay the content, I successfully resolved these issues, guaranteeing consistent presentation and functionality across devices. </p>
              </Col>
            <Col lg={6} className="order-lg-1">
            <Image src={RespImg} alt="App Preview" />
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5} className="order-lg-2 mb-5 mb-lg-0">
              <h2 className="d-flex align-items-center">CSS Animations and Form Submission</h2>
              
              <p className="mb-4">To enhance the visual appeal of the project while maintaining a visually simple interface, I incorporated CSS animations for images and text. These animations were strategically implemented to catch the eye of users.</p>
              <p className="mb-4">Incorporating a contact form into the project, I integrated Email.js for seamless form submission to my email. To enhance security and prevent spam, I implemented reCAPTCHA for spam detection. </p>

            </Col>
            <Col lg={6} className="order-lg-1">
            <Ratio aspectRatio="1x1">
        <embed type="video/mp4" src={Video1} />
      </Ratio>
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5}>
              <h2 className="d-flex align-items-center">Continuous Deployment</h2>
              
              <p className="mb-4">
              Deploying the project to Azure was made efficient and automated through continuous deployment using GitHub Actions. By configuring workflows, I established a seamless process for deploying updates to the Azure cloud environment. 
              </p> </Col>
            <Col lg={6}>
              <Image src={DeployImg} alt="Deploy Preview" />
            </Col>
          </Row>
          
        </Container>
      </section>
      
    </>
  );
};
