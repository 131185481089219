import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudSunRain, faExternalLinkAlt, faTimesCircle, faCheckCircle, faLocationDot, faCodeBranch, faShoppingCart, faFolder, faMapMarkedAlt, faPager, faFileCode, faDownload } from "@fortawesome/free-solid-svg-icons";
import {faGithub, faJs, faReact } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Card, Image, Button, Container, ListGroup, Tooltip, OverlayTrigger, Form, Navbar, Nav, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Icon } from '@iconify/react';
import Presentation from "../assets/img/Webapp_full.png";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import TextImg from "../assets/img/webapp02.png";
import AuthImg from "../assets/img/webpage08.png";
import DeployImg from "../assets/img/webpage07.png";
import ReactImg from "../assets/img/webapp01.png";

export default () => {
  const PagePreview = (props) => {
    const { name, image, link } = props;

    return (
      <Col xs={6} className="mb-5">
        <Card.Link as={Link} to={link} className="page-preview page-preview-lg scale-up-hover-2">
          <Image src={image} className="shadow-lg rounded scale" alt="Dashboard page preview" />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name} <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  const Feature = (props) => {
    const { title, description, icon } = props;

    return (
      <Col xs={12} sm={6} lg={3}>
        <Card className="bg-white shadow-soft text-primary rounded mb-4">
          <div className="px-3 px-lg-4 py-5 text-center">
            <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span>
            <h5 className="fw-bold text-primary">{title}</h5>
            <p>{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const FolderItem = (props) => {
    const { name, icon, tooltip, iconColor } = props;
    const color = iconColor ? `text-${iconColor}` : "";

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="left"
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <li data-toggle="tooltip" data-placement="left" title="Main folder that you will be working with">
          <FontAwesomeIcon icon={icon ? icon : faFolder} className={`${color} me-2`} /> {name}
        </li>
      </OverlayTrigger>
    );
  };


  return (
    <>
      <Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand as={HashLink} to="#home" className="me-lg-3 d-flex align-items-center">
            <Image src={ReactHero} />
            <span className="ms-2 brand-text d-none d-md-inline">Web App</span>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
          <span className="ms-2 brand-text d-none d-md-inline">Created with FARM Stack</span>
          </div>
        </Container>
      </Navbar>
      <section className="section-header overflow-hidden pt-5 pt-lg-6 pb-9 pb-lg-12 bg-primary text-white" id="home">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <div className="react-big-icon d-none d-lg-block"><span className="fab fa-react"></span></div>
              <p className="text-muted fw-light mb-5 h5">Web App leverages the FARM stack to create a versatile platform for small businesses. Motivated by the need for accessible and customizable web solutions, I crafted this application to showcase the seamless integration of React.js for frontend flexibility, FastAPI for backend efficiency, and MongoDB for scalable data management. With a keen emphasis on responsive design and interactive components, it aims to give small business owners a robust online presence tailored to their unique needs.</p>
              <div className="d-flex align-items-center justify-content-center">
                </div>
              <div className="d-flex justify-content-center flex-column mb-6 mb-lg-5 mt-5">
                <div className="text-center">
                  
                </div>
              </div>
              <div className="d-flex justify-content-center flex-column mb-6 mb-lg-5 mt-5">
                <div className="text-center">
                  
                </div>
              </div>
            </Col>
          </Row>
          <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2">
            <svg className="fill-soft" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
              <path d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z" />
            </svg>
          </figure>
        </Container>
      </section>
      <div className="section pt-0">
        <Container className="mt-n10 mt-lg-n12 z-2">
          <Row className="justify-content-center">
            <Col xs={12}>
              <Image src={Presentation} alt="Web App presentation" />
            </Col>
          </Row>
          <Row className="justify-content-center mt-5 mt-lg-6">
          <Col xs={6} md={3} className="text-center mb-4">
  <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
    <FontAwesomeIcon icon={faReact} className="text-secondary" />
  </div>
  <h3 className="fw-bolder">React.js</h3>
  <p className="text-gray">Efficient Frontend Development</p>
</Col> 

<Col xs={6} md={3} className="text-center mb-4">
  <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
    <Icon icon="logos:fastapi-icon" className="text-secondary"/>
  </div>
  <h3 className="fw-bolder">FastAPI</h3>
  <p className="text-gray">Powerful Backend Framework</p>
</Col>

<Col xs={6} md={3} className="text-center">
  <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
    <Icon icon="logos:mongodb-icon" className="text-secondary"/>
  </div>
  <h3 className="fw-bolder">MongoDB</h3>
  <p className="text-gray">Scalable NoSQL Database</p>
</Col>

<Col xs={6} md={3} className="text-center">
  <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
    <Icon icon="logos:vercel-icon" className="text-secondary"/>
  </div>
  <h3 className="fw-bolder">Azure and Vercel</h3>
  <p className="text-gray">Effortless Deployment</p>
</Col>
          </Row>
        </Container>
      </div>
      <section className="section section-md bg-soft pt-lg-3" id="features">
        <Container>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5} className="order-lg-2 mb-5 mb-lg-0">
              <h2>Responsive Design</h2>
              <p className="mb-4">The app ensures a seamless user experience across all devices. Utilizing hooks and Bootstrap 5's responsive grid system, the application dynamically adjusts layouts for optimal viewing on desktops, tablets, and mobile devices.
              </p>
              </Col>
            <Col lg={6} className="order-lg-1">
              <Image src={ReactImg} alt="App Preview" />
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5}>
              <h2>Authentication and Database Usage</h2>
              <p className="mb-4">The app implements robust authentication mechanisms and leverages MongoDB for efficient data storage. With FastAPI handling backend operations, the application securely manages user access and seamlessly integrates with the MongoDB database for reliable data storage and retrieval.
              </p>
              </Col>
            <Col lg={6} className="order-lg-1">
            <Image src={AuthImg} alt="App Preview" />
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5} className="order-lg-2 mb-5 mb-lg-0">
              <h2 className="d-flex align-items-center">Text Content Management</h2>
              
              <p className="mb-4">One of the standout features of the app is its intuitive text content management system. Designed with simplicity in mind, users with admin privileges can effortlessly modify website content through an easy-to-use form interface. This feature allows for real-time updates to text content, empowering businesses to keep their online presence fresh and engaging without requiring any technical expertise. Moreover, users have the flexibility to create, modify, add, and delete text chapters, providing complete control over their website's content structure.
              </p>
              

            </Col>
            <Col lg={6} className="order-lg-1">
            <Image src={TextImg} alt="App Preview" />
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5}>
              <h2 className="d-flex align-items-center">Deployment and Scalability</h2>
              
              <p className="mb-4">
              Deployed on Azure for backend operations and Vercel for frontend hosting, this web app demonstrates my proficiency in deploying and scaling web applications in cloud environments. This architecture ensures high availability, scalability, and performance, making it ideal for businesses with varying demands.
               </p></Col>
            <Col lg={6}>
              <Image src={DeployImg} alt="Deploy Preview" />
            </Col>
          </Row>
          
        </Container>
      </section>
      
     
    </>
  );
};
