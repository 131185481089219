import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Routes as RoutesConfig } from "./routes";
import Layout from "./components/Layout";
import Presentation from "./pages/Presentation";
import OnePager from "./pages/OnePager";
import Native from "./pages/Native";
import WebApp from './pages/WebApp';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />} >
      <Route index element={<Presentation />} />
      <Route path={RoutesConfig.OnePager.path} element={<OnePager />} />
      <Route path={RoutesConfig.Native.path} element={<Native />} />
      <Route path={RoutesConfig.WebApp.path} element={<WebApp />} />

      </Route>
      <Route path="*" element={<Navigate to={RoutesConfig.NotFound.path} />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
