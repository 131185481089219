import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';


const Layout = () => {
    return (
        <>
          <Sidebar />
          <main  className="content">
            <Outlet />
          </main>
        </>
    );
};

export default Layout;