import Native from "./pages/Native";
import WebApp from "./pages/WebApp";

export const Routes = {
    // pages
    Presentation: { path: "/" },
    OnePager: { path: "/onepager" },
    Native: { path: "/native" },
    WebApp: { path: "/webapp" },

    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
};